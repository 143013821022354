import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const NotFoundPage: React.FC = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{ padding: 2, color: 'white' }}
        >
            <Typography variant="h1" component="h1" gutterBottom sx={{ marginBottom: 0 }}>
                404
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: 0 }}>
                Page Not Found
            </Typography>
            <Box display="flex" alignItems="center" marginTop={2}>
                <HomeIcon sx={{ marginRight: 1 }} />
                <Link href="/" sx={{ color: 'inherit', textDecoration: 'none', '&:hover': { color: '#00bcd4' } }}>
                    Go Home
                </Link>
            </Box>
        </Box>
    );
};

export default NotFoundPage;
