import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from './Loader';
import ProfileCard from './ProfileCard';
import { githubModel } from '../../models/models';

const FirstPage: React.FC = () => {
    const url = 'https://api.github.com/users/engjellavdiu';

    const [user, setUser] = useState<githubModel | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .get(url)
            .then((response) => setUser(response.data))
            .finally(() => setLoading(false));
    }, [url]);

    if (loading || !user) {
        return <Loader />;
    }

    return <ProfileCard user={user} />;
};

export default FirstPage;
