import React from 'react';
import { Card, CardContent, CardMedia, CardActionArea, Typography, Button, Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import { githubModel } from '../../models/models';

interface ProfileCardProps {
    user: githubModel;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            // justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{ paddingTop: 10 }}
        >
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea sx={{ justifyContent: "center", margin: 0 }} href={`https://github.com/${user.login}`}>
                    <CardMedia component="img" image={user.avatar_url} alt="profile picture" />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography gutterBottom variant="h5" component="div">
                            <div>{user.name}</div>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" fontWeight="bold" fontSize={14}>
                            {user.bio}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Joined {new Date(user.created_at).toLocaleDateString()}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Box mt={2}>
                <Button
                    variant="contained"
                    sx={{ margin: 1, backgroundColor: '#006368', '&:hover': { backgroundColor: '#004f4e' } }}
                    href={`https://github.com/${user.login}`}
                    startIcon={<GitHubIcon />}
                >
                    GitHub
                </Button>
                <Button
                    variant="contained"
                    sx={{ margin: 1, backgroundColor: '#006368', '&:hover': { backgroundColor: '#004f4e' } }}
                    href={`mailto:${user.email}`}
                    startIcon={<MailIcon />}
                >
                    Mail
                </Button>
                <Button
                    variant="contained"
                    sx={{ margin: 1, backgroundColor: '#006368', '&:hover': { backgroundColor: '#004f4e' } }}
                    href={`https://www.linkedin.com/in/${user.login}/`}
                    startIcon={<LinkedInIcon />}
                >
                    LinkedIn
                </Button>
            </Box>
        </Box >
    );
};

export default ProfileCard;
