import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/styles/index.css"
import ReactGA from 'react-ga';

ReactGA.initialize('G-7EECEF4S88');
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div style={{ height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden' }}>
      {<App />}
    </div>
  </React.StrictMode>
);
