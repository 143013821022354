import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import './assets/styles/index.css';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Engjell Avdiu - Software Engineer</title>
        <meta name="description" content="Engjell Avdiu's personal website." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.engjellavdiu.com/" />
      </Helmet>
      <div style={{ height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden', backgroundColor: '#2D2D2D' }}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
