import React from 'react';

const loaderStyle = `
  .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #1e1e1e;
  }

  .loader-inner {
      width: 80px;
      height: 80px;
      border: 10px solid rgba(255, 255, 255, 0.1);
      border-top: 10px solid #09d3ac;
      border-radius: 50%;
      animation: spin 1s linear infinite;
  }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
`;

const Loader: React.FC = () => {
    return (
        <div className="loader">
            <div className="loader-inner"></div>
            <style>{loaderStyle}</style>
        </div>
    );
};

export default Loader;
