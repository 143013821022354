import React from 'react';
import FirstPage from '../components/LandingPage/FirstPage';

const LandingPage: React.FC = () => {
    return (
        <div>
            <FirstPage />
        </div>
    );
};

export default LandingPage;
